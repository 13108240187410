export const servicesObjOne = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'When cars meet passion',
    headline: 'Custom Wrap Designs',
    description:
        'With our love and passion in vinyl wraps, we offer custom wrap designs for all types of vehicles. It includes Itasha wraps, custom graphic designs, commercial wraps and much more. The limit is your imagination.',
    imgStart: '',
    img: 'images/wraps1.jpg',
    alt: 'aspwraps'
};

export const servicesObjTwo = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Cruising down the street with custom liveries',
    headline: 'Racing Livery Wrap',
    description:
        'Our graphic designers will create and illustrate the livery design according to your team\'s cars and requirements. At the very next trackday, your team will be the shinniest among them all. Custom decals are also available :)',
    imgStart: 'start',
    img: 'images/wraps2.jpg',
    alt: 'aspwraps'
};

export const servicesObjThree = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Of course the basics',
    headline: 'Full Car Color Change',
    description:
        "Want another color for the car? We offer full car wraps with top quality vinyl materials from brands like 3M, vvivd, Avery, Arlon, and KPMF. The choice is yours :)",
    imgStart: '',
    img: 'images/wraps3.jpg',
    alt: 'aspwraps'
};
