import React from 'react'
import ASPWrapsHeroSection from '../../ASPWrapsHeroSection'
import { servicesObjOne, servicesObjTwo, servicesObjThree, servicesObjFour } from './Data'

function Services() {
    return (
        <>
            <ASPWrapsHeroSection {...servicesObjOne} />
            <ASPWrapsHeroSection {...servicesObjTwo} />
            <ASPWrapsHeroSection {...servicesObjThree} />
        </>
    )
}

export default Services
