export const servicesObjOne = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Mobile Mechanical Services',
    headline: 'Maintenance and Mechanical Repair',
    description: 'We are dedicated to provide vehicle diagnosis, maintenance services, and mechanical repairs with dealer level quality. Our mobile mechanics will come to your place to get the job done while you enjoy the convenience of staying at home.',
    imgStart: '',
    img: 'images/services1.jpg',
    alt: 'Services'
};

export const servicesObjTwo = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Faster Prettier Better',
    headline: 'Performance Modifications',
    description:
        'From simple tyre swaps to track-ready racing builds, we offer wide range of performance modifications that meet your needs including engine tuning, suspensions tuning, aerodynamics, handling, and cosmetics.',
    imgStart: 'start',
    img: 'images/services2.jpg',
    alt: 'Services'
};

export const servicesObjThree = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Let\'s spice up the game',
    headline: 'Trackdays and Special Events',
    description:
        'Join us on trackdays and other events like car meets, casual cruise, and even coin washes :)',
    imgStart: '',
    img: 'images/services3.jpg',
    alt: 'Services'
};
