import React from 'react';
import '../App.css';
import { Button } from './button';
import './HeroSection.css';

function HeroSection() {
    return (
        <div className='hero-container'>
            {/* <video src='/videos/video-2.mp4' autoPlay loop muted /> */}
            <img src={process.env.PUBLIC_URL + 'images/asp_white.png'} /> 
            <p>Mobile Mechanical Services</p>
            <div className='hero-btns'>
                <Button className='btns' buttonStyle='btn--outline' buttonSize='btn--large'>
                    Go to Services
                </Button>
            </div>
        </div>
    );
}

export default HeroSection;
