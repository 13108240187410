import React from 'react';
import './ASPWrapsHeroSection.css';

function ASPWrapsHeroSection({
    lightBg,
    topLine,
    lightText,
    lightTextDesc,
    headline,
    description,
    img,
    alt,
    imgStart
}) {
    return (
        <>
            <div
                className={lightBg ? 'aspwraps__hero-section' : 'aspwraps__hero-section darkBg'}
            >
                <div className='container'>
                    <div
                        className='row aspwraps__hero-row'
                        style={{
                            display: 'flex',
                            flexDirection: imgStart === 'start' ? 'row-reverse' : 'row'
                        }}
                    >
                        <div className='col'>
                            <div className='aspwraps__hero-text-wrapper'>
                                <div className='top-line'>{topLine}</div>
                                <h1 className={lightText ? 'heading' : 'heading dark'}>
                                    {headline}
                                </h1>
                                <p
                                    className={
                                        lightTextDesc
                                            ? 'aspwraps__hero-subtitle'
                                            : 'aspwraps__hero-subtitle dark'
                                    }
                                >
                                    {description}
                                </p>
                            </div>
                        </div>
                        <div className='col'>
                            <div className='aspwraps__hero-img-wrapper'>
                                <img src={img} alt={alt} className='aspwraps__hero-img' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ASPWrapsHeroSection;
