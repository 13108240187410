import React from 'react';
import './ServicesHeroSection.css';

function ServicesHeroSection({
    lightBg, 
    topLine, 
    lightText, 
    lightTextDesc, 
    headline, 
    description, 
    img, 
    alt,
    imgStart
}) {
    return (
        <>
            <div className={lightBg ? 'services__hero-section' : 'services__hero-section darkBg'}>
                <div className="container">
                    <div className="row services__hero-row" 
                        style={{
                            // display: 'flex', 
                            flexDirection: imgStart === 'start' ? 'row-reverse' : 'row'
                        }
                    }>
                        <div className='col'>
                            <div className="services__hero-text-wrapper">
                                <div className="top-line">{topLine}</div>
                                <h1 className={lightText ? 'heading' : 'heading dark'}>{headline}</h1>
                                <p className={lightTextDesc 
                                    ? 'services__hero-subtitle' 
                                    : 'services__hero-subtitle dark'
                                }>
                                    {description}
                                </p>
                            </div>
                        </div>
                        <div className="col">
                            <div className="services__hero-img-wrapper">
                                <img src={img} alt={alt} className="services__hero-img"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ServicesHeroSection
