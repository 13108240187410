import React from 'react'
import CardItem from './CardItem'
import './Cards.css'

function Cards() {
    return (
        <div className='cards'>
            <h1>Check out the services we provide</h1>
            <div className="cards-container">
                <div className="cards-wrapper">
                    <ul className="cards-items">
                        <CardItem 
                            src="images/services_card.jpg"
                            text="See our mobile mechanical services and performance upgrades"
                            label='Auto Services'
                            path='/services'
                        />
                        <CardItem 
                            src="images/aspwraps_card.png"
                            text="See our vinyl wraps and custom design options"
                            label='ASP Wraps'
                            path='/aspwraps'
                        />
                        <CardItem 
                            src="images/contact_us_card.png"
                            text="Send us a message and we will get back to you"
                            label='Contact Us'
                            path='/contactus'
                        />
                    </ul>
                    {/* if two rows of cards needed, simply create another ul tag with CardItems */}
                </div>
            </div>
        </div>
    )
}

export default Cards
