import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Navbar from './components/Navbar';

import './App.css';
import ScrollToTop from './components/ScrollToTop'
import Home from './components/pages/HomePage/Home.js'
import Services from './components/pages/ServicesPage/Services'
import ASPWraps from './components/pages/ASPWrapsPage/ASPWraps.js'
import ContactUs from './components/pages/ContactUsPage/ContactUs.js'
import Footer from './components/Footer.js'


function App() {
  return (
    <>
    <Router>
      <ScrollToTop />
      <Navbar />
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/services' component={Services} />
        <Route path='/aspwraps' component={ASPWraps} />
        <Route path='/contactus' component={ContactUs} />
      </Switch>
      <Footer />
    </Router>
    </>
  );
}

export default App;
