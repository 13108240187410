import firebase from 'firebase';

var firebaseApp = firebase.initializeApp({
// Your web app's Firebase configuration
    apiKey: "AIzaSyCz85vOIagpCDzSKGShlydV88IacvFITvg",
    authDomain: "aspwraps-9210b.firebaseapp.com",
    databaseURL: "https://aspwraps-9210b.firebaseio.com",
    projectId: "aspwraps-9210b",
    storageBucket: "aspwraps-9210b.appspot.com",
    messagingSenderId: "570746029208",
    appId: "1:570746029208:web:4551475676947c2918bced"
})

var db = firebaseApp.firestore();

export { db };
