import React from 'react'
import { useState } from 'react'
import './ContactUs.css'
import { db } from '../../firebase.js';


const ContactUs = () => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();

        db.collection('contacts').add({
            name: name,
            email: email,
            phone: phone,
            message: message,
        })
            .then(() => {
                alert("Request has been send, please give us a moment to respond to your request. Have a nice day :)");
            })
            .catch((error) => {
                alert(error.message);
            });

        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
    };

    return (
        <form className="form" onSubmit={handleSubmit}>
            <h1 className='contactus__title'>Contact Us</h1>
            <h4 align='center'>Feel free to email us, call us, send us DMs on instagram</h4>
            <h4 align='center'>Or simply send us a message below</h4>

            {/* <label>For Services:</label> */}
            <div className="info__container">
                <div className='row'>
                    <div className='col'>
                        <div className="for__services">
                            <h2>For Services:</h2>
                            <p className='info-instagram'>Instagram: @4projectasp</p>
                            <p className='info-email'>Email: 4projectasp@gmail.com</p>
                            <p className='info-phone'>Phone: +1 (416) 721-2823</p>
                        </div>
                    </div>
                    <div className='col'>
                        <div className="for__aspwraps">
                            <h2>For ASP Wraps:</h2>
                            <p className='info-instagram'>Instagram: @aspwraps</p>
                            <p className='info-email'>Email: aspwraps@gmail.com</p>
                            <p className='info-phone'>Phone: N/A</p>
                        </div>
                    </div>

                </div>
            </div>

            <label>Name:</label>
            <input
                placeholder="Name"
                value={name}
                onChange={(event) => setName(event.target.value)}
            />

            <label>Email:</label>
            <input
                placeholder="Email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
            />

            <label>Phone Number:</label>
            <input
                placeholder="Phone Number"
                value={phone}
                onChange={(event) => setPhone(event.target.value)}
            />

            <label>Message:</label>
            <textarea
                placeholder="What services do you need"
                rows="5"
                value={message}
                onChange={(event) => setMessage(event.target.value)}
            ></textarea>

            <button
                type="submit"
            >
                Submit
            </button>
        </form>
    )
}

export default ContactUs
