import React from 'react'
import { Button } from './button'
import './Footer.css'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <div className='footer-container'>
            <section className="footer-subscription">
                <p className="footer-subscription-heading">
                    Join the ASP newsletter to recieve our best service updates
                </p>
                <p className="footer-subscription-text">
                    You can unsubscribe at any time.
                </p>
                <div className="input-areas">
                    <form>
                        <input type="email" name="email" placeholder="Your Email" className="footer-input" />
                        <Button buttonStyle='btn--outline'>Subscribe</Button>
                    </form>
                </div>
            </section>
            <div className="footer-links">
                <div className="footer-link-wrapper">
                    <div className="footer-link-items">
                        <h2>Services</h2>
                        <Link to='/services'>See what we do</Link>
                        <Link to='/contactus'>Contact Us</Link>
                    </div>
                    <div className="footer-link-items">
                        <h2>ASP Wraps</h2>
                        <Link to='/aspwraps'>ASP Wraps</Link>
                    </div>
                    <div className="footer-link-items">
                        <h2>Contact Us</h2>
                        <Link to='/contactus'>Contact Us</Link>
                    </div>
                </div>
                <div className='footer-link-wrapper'>
                    <div class='footer-link-items'>
                        <h2>Social Media</h2>
                        <Link to='/'>Instagram</Link>
                        <Link to='/'>Facebook</Link>
                    </div>
                </div>
            </div>
            <section class='social-media'>
                <div class='social-media-wrap'>
                    <div class='footer-logo'>
                        <Link to='/' className='social-logo'>
                            ASP Performance
                        </Link>
                    </div>
                    <small class='website-rights'>ASP © 2021</small>
                    <div class='social-icons'>
                        <Link class='social-icon-link facebook' to='/contactus' target='_blank' aria-label='Facebook'>
                            <i class='fab fa-facebook-f' />
                        </Link>
                        <Link class='social-icon-link instagram' to='/contactus' target='_blank' aria-label='Instagram'>
                            <i class='fab fa-instagram' />
                        </Link>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Footer
